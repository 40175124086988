



































































































import { defineComponent, onActivated, reactive } from "@vue/composition-api";
import router from "@/router";
import { listVM } from "../modules/stores/targetKeterampilan";
import { toUserDateFormat } from "@/apps/core/modules/utils/datetime";
import useInfiniteScrollList from "@/apps/core/modules/useInfiniteScrollList";
import useListFilter from "@/apps/core/modules/useListFilter";
import { GenericListAPI } from "@/apps/core/api/list";

export default defineComponent({
  name: "TargetKeterampilanList",
  components: {
    Box: () => import("@/apps/core/components/Box.vue"),
    ListFilter: () => import("@/apps/core/components/ListFilter.vue"),
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup() {
    const angkatanOption: Record<string, any> = {
      field: "angkatan",
      value: "",
      valueMap: { "": "Angkatan" },
    };
    const composition = useInfiniteScrollList(listVM, false);

    const filterButtonConfigs: Record<string, any> = reactive([angkatanOption]);
    const getAngkatanOptions = async () => {
      const api = new GenericListAPI("/programstudi/angkatan/options/");
      const optionArr = await api.fetch();
      const options = optionArr.reduce((preVal, curVal) => {
        preVal[curVal.id] = toUserDateFormat(curVal.nama);
        return preVal;
      }, {});
      angkatanOption.valueMap = Object.assign(
        {},
        angkatanOption.valueMap,
        options
      );
    };
    getAngkatanOptions();

    onActivated(() => {
      if (listVM.getRefreshRequest() !== "") {
        // ada permintaan refresh list (dengan initial filtering)
        listVM.applyFilter(listVM.refreshRequest.filterMap);
        if (listVM.refreshRequest.filterMap) {
          // reset filtering buttons
          for (let idx = 0; idx < filterButtonConfigs.length; idx++) {
            filterButtonConfigs[idx].value = "";
          }
          const angkatan = listVM.refreshRequest.filterMap.angkatan;
          if (angkatan) filterButtonConfigs[0].value = angkatan;
        }
        listVM.resetRefreshRequest();
      } else if (listVM.list.length === 0) {
        // ketika listview pertama kali dipanggil, meload data list
        // asumsinya kalau list kosong, perlu fetch data dulu
        composition.onPageChange();
      }
    });

    return {
      // Data
      filterButtonConfigs,

      // Method
      router,
      toUserDateFormat,

      // Composition
      ...composition,
      ...useListFilter(),
    };
  },
});
