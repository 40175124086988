














































































import { defineComponent } from "@vue/composition-api";
import { DialogProgrammatic as Dialog } from "buefy";
import { listVM } from "../modules/stores/suratTugas";
import SuratTugasVM, { SuratTugas } from "../models/suratTugas";
import { isKonsulen } from "@/apps/accounts/modules/store";
import { toUserDateFormat } from "@/apps/core/modules/utils/datetime";
import useInfiniteScrollList from "@/apps/core/modules/useInfiniteScrollList";
import useListFilter from "@/apps/core/modules/useListFilter";

export default defineComponent({
  name: "SuratTugasList",
  components: {
    Box: () => import("@/apps/core/components/Box.vue"),
    ContextMenu: () => import("@/apps/core/components/ContextMenu.vue"),
    ListFilter: () => import("@/apps/core/components/ListFilter.vue"),
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup() {
    const getMenuConfigs = (surat: SuratTugas) => [
      {
        label: "Hapus",
        onClick: () => {
          Dialog.confirm({
            message: "Hapus surat ini?",
            onConfirm: async () => {
              const suratVM = new SuratTugasVM();
              if (surat.id === null) return;
              await suratVM.api.delete(surat.id);
              const idx = listVM.list.indexOf(surat);
              listVM.list.splice(idx, 1);
            },
          });
        },
      },
    ];
    let action = null;
    if (isKonsulen.value) action = { type: "add", routeName: "surat-tugas-add" };

    return {
      // Data
      action,
      // menuConfigs,

      // Computed
      isKonsulen,

      // Method
      getMenuConfigs,
      toUserDateFormat,

      // Composition
      // ...useListViewModel(listVM),
      ...useInfiniteScrollList(listVM),
      ...useListFilter(),
    };
  },
});
