import { render, staticRenderFns } from "./AngkatanDetail.vue?vue&type=template&id=0b545b84&scoped=true&"
import script from "./AngkatanDetail.vue?vue&type=script&lang=ts&"
export * from "./AngkatanDetail.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.9.7_679359cdb69c218f2f8f476b2ba08796/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b545b84",
  null
  
)

export default component.exports