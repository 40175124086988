import FormModel from "@/apps/core/models/formModel";
import {
  TargetKeterampilan,
  TargetKeterampilanAPI,
} from "./targetKeterampilan";
import { setRefreshRequest } from "../modules/stores/targetKeterampilan";

export default class TargetKeterampilanFM extends FormModel {
  constructor(requiredFields: Array<string>, nonRequiredFields: Array<string>) {
    super(
      new TargetKeterampilanAPI(),
      new TargetKeterampilan(),
      requiredFields,
      nonRequiredFields,
      setRefreshRequest
    );
  }

  getPayload() {
    const payload = super.getPayload();
    payload.target1 = payload.target_1;
    payload.target2 = payload.target_2;
    payload.target3 = payload.target_3;

    delete payload.target_1;
    delete payload.target_2;
    delete payload.target_3;
    return payload;
  }

  /*
  Yang akan digunakan di vue single file component adalah:
  - instance
  - errorMap
  - validity.getIsValid() (computed)
  - setEdited (method)
  - validate (method)
  - save (method)
  */
}
