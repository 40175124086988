import { Angkatan, AngkatanAPI } from "./angkatan";
import FormModel from "@/apps/core/models/formModel";
import { setRefreshRequest } from "../modules/stores/angkatan";

export default class AngkatanFM extends FormModel {
  constructor(requiredFields: Array<string>, nonRequiredFields: Array<string>) {
    super(
      new AngkatanAPI(),
      new Angkatan(),
      requiredFields,
      nonRequiredFields,
      setRefreshRequest
    );
  }

  /*
  Yang akan digunakan di vue single file component adalah:
  - instance
  - errorMap
  - validity.getIsValid() (computed)
  - setEdited (method)
  - validate (method)
  - save (method)
  */
}
