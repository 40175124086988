



























































































import axios from "axios";
import { debounce } from "lodash";
import { defineComponent } from "@vue/composition-api";
import APP_CONFIG from "@/apps/core/modules/config";
import { isAdmin } from "@/apps/accounts/modules/store";
import { TargetDiagnosis } from "../models/targetDiagnosis";
import TargetDiagnosisListVM from "../models/targetDiagnosisList";
import { toUserDateFormat } from "@/apps/core/modules/utils/datetime";
import useInfiniteScrollList from "@/apps/core/modules/useInfiniteScrollList";
import useListFilter from "@/apps/core/modules/useListFilter";
import { ToastProgrammatic as Toast } from "buefy";

export default defineComponent({
  name: "TargetDiagBatchList",
  components: {
    Box: () => import("@/apps/core/components/Box.vue"),
    ListFilter: () => import("@/apps/core/components/ListFilter.vue"),
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup() {
    const listVM = new TargetDiagnosisListVM("create");
    const url = `${APP_CONFIG.baseAPIURL}/programstudi/target-diagnosis/`;
    const updateTarget = debounce(async (target: TargetDiagnosis) => {
      const payload = {
        angkatan: target.angkatan?.id,
        diagnosis: target.diagnosis?.id,
        target: target.target,
      };
      try {
        if (target.id) {
          await axios.patch(`${url}${target.id}/`, payload);
        } else {
          const response = await axios.post(url, payload);
          target.id = response.data.id;
        }
        Toast.open("Data berhasil di simpan.");
        const idx = listVM.list.indexOf(target);
        listVM.list.splice(idx, 1);
      } catch {
        Toast.open("Data gagal di simpan.");
      }
    }, 2000);

    return {
      // Data
      isAdmin,

      // Method
      updateTarget,
      toUserDateFormat,

      // Composition
      // ...useListViewModel(listVM),
      ...useInfiniteScrollList(listVM),
      ...useListFilter(),
    };
  },
});
