















































import { defineComponent } from "@vue/composition-api";
import AngkatanFM from "../models/angkatanForm";
import useFormModel from "@/apps/core/modules/useFormModel";

/* Hanya create saja, tanpa updated, tidak perlu paramsId dari router */
export default defineComponent({
  name: "AngkatanForm",
  components: {
    DatePicker: () => import("@/apps/core/components/DatePicker.vue"),
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup() {
    // BUAT CONTOH!!!! -> const isCreate = !has(router.currentRoute.params.id, "id");
    const requiredFields = ["mulai"];
    const form = new AngkatanFM(requiredFields, []);
    const composition = useFormModel(form);
    const angkatan = composition.instance;

    return {
      // Data
      angkatan,

      // Composition
      ...composition
    };
  }
});
