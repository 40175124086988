


















import { defineComponent } from "@vue/composition-api";
import AngkatanVM from "../models/angkatan";
import router from "@/router";
import { toUserDateFormat } from "@/apps/core/modules/utils/datetime";
import useViewModel from "@/apps/core/modules/useViewModel";

export default defineComponent({
  name: "AngkatanDetail",
  components: {
    DetailField: () => import("@/apps/core/components/DetailField.vue"),
    ResidenTable: () =>
      import("@/apps/programstudi/components/ResidenTable.vue"),
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup() {
    const paramsId = router.currentRoute.params.id;
    const angkatanVM = new AngkatanVM();
    const { viewModelRef, instance } = useViewModel(paramsId, angkatanVM);
    return {
      // Data
      viewModelRef,
      angkatan: instance,
      toUserDateFormat,
    };
  },
});
