













































































import { defineComponent } from "@vue/composition-api";
import { DialogProgrammatic as Dialog } from "buefy";
import { listVM } from "../modules/stores/informasi";
import InformasiVM, { Informasi } from "../models/informasi";
import { isAdmin } from "@/apps/accounts/modules/store";
import { toUserDateFormat } from "@/apps/core/modules/utils/datetime";
import useInfiniteScrollList from "@/apps/core/modules/useInfiniteScrollList";
import useListFilter from "@/apps/core/modules/useListFilter";

export default defineComponent({
  name: "InformasiList",
  components: {
    Box: () => import("@/apps/core/components/Box.vue"),
    ContextMenu: () => import("@/apps/core/components/ContextMenu.vue"),
    ListFilter: () => import("@/apps/core/components/ListFilter.vue"),
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup() {
    const getMenuConfigs = (informasi: Informasi) => [
      {
        label: "Hapus",
        onClick: () => {
          Dialog.confirm({
            message: "Hapus informasi ini?",
            onConfirm: async () => {
              const informasiVM = new InformasiVM();
              if (informasi.id === null) return;
              await informasiVM.api.delete(informasi.id);
              const idx = listVM.list.indexOf(informasi);
              listVM.list.splice(idx, 1);
            },
          });
        },
      },
    ];
    let action = null;
    if (isAdmin.value) action = { type: "add", routeName: "pengumuman-add" };

    return {
      // Data
      action,
      // menuConfigs,

      // Computed
      isAdmin,

      // Method
      getMenuConfigs,
      toUserDateFormat,

      // Composition
      // ...useListViewModel(listVM),
      ...useInfiniteScrollList(listVM),
      ...useListFilter(),
    };
  },
});
