

















































































import { defineComponent } from "@vue/composition-api";
import router from "@/router";
import TargetDiagnosisFM from "../models/targetDiagnosisForm";
import useFormModel from "@/apps/core/modules/useFormModel";

export default defineComponent({
  name: "TargetDiagnosisForm",
  components: {
    GenericAutocomplete: () =>
      import("@/apps/core/components/GenericAutocomplete.vue"),
    GenericSelect: () => import("@/apps/core/components/GenericSelect.vue"),
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup() {
    // BUAT CONTOH!!!! -> const isCreate = !has(router.currentRoute.params.id, "id");
    const requiredFields = ["angkatan", "diagnosis", "target"];
    const form = new TargetDiagnosisFM(requiredFields);
    const composition = useFormModel(form, router.currentRoute.params.id);
    const targetDiag = composition.instance;

    return {
      // Data
      targetDiag,

      // Composition
      ...composition,
    };
  },
});
