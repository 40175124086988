














































































import { defineComponent } from "@vue/composition-api";
import { listVM } from "../modules/stores/angkatan";
import { toUserDateFormat } from "@/apps/core/modules/utils/datetime";
import useInfiniteScrollList from "@/apps/core/modules/useInfiniteScrollList";
import useListFilter from "@/apps/core/modules/useListFilter";

export default defineComponent({
  name: "AngkatanList",
  components: {
    Box: () => import("@/apps/core/components/Box.vue"),
    ListFilter: () => import("@/apps/core/components/ListFilter.vue"),
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup() {
    return {
      // Method
      toUserDateFormat,

      // Composition
      // ...useListViewModel(listVM),
      ...useInfiniteScrollList(listVM),
      ...useListFilter(),
    };
  },
});
