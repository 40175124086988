import FormModel from "@/apps/core/models/formModel";
import { TargetDiagnosis, TargetDiagnosisAPI } from "./targetDiagnosis";
import { setRefreshRequest } from "../modules/stores/targetDiagnosis";

export default class TargetDiagnosisFM extends FormModel {
  constructor(
    requiredFields: Array<string>,
    nonRequiredFields: Array<string> = []
  ) {
    super(
      new TargetDiagnosisAPI(),
      new TargetDiagnosis(),
      requiredFields,
      nonRequiredFields,
      setRefreshRequest
    );
  }

  /*
  Yang akan digunakan di vue single file component adalah:
  - instance
  - errorMap
  - validity.getIsValid() (computed)
  - setEdited (method)
  - validate (method)
  - save (method)
  */
}
