




















































































import { defineComponent } from "@vue/composition-api";
import { Informasi } from "../models/informasi";
import InformasiFM from "../models/informasiForm";
import { SaveContext } from "@/apps/core/models/formModel";
import UploadPhoto from "@/apps/core/modules/uploadPhoto";
import useFormModel from "@/apps/core/modules/useFormModel";

/* Hanya create saja, tanpa updated, tidak perlu paramsId dari router */
export default defineComponent({
  name: "InformasiForm",
  components: {
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup() {
    const form = new InformasiFM();
    const composition = useFormModel(form);
    let foto: File | null = null;
    const fotoData: File | null = null;
    const informasi = form.instance as Informasi;

    const uploadPhoto = new UploadPhoto("informasi");
    uploadPhoto.requestAWS(null, null);

    const resize = (_foto: File) => {
      uploadPhoto.resize(_foto, (resizedFoto: File, url: string) => {
        foto = resizedFoto;
        informasi.url = url;
        form.validate("url");
      });
    }

    const saveForm = () => form.save();

    const upload = () => {
      uploadPhoto.upload(foto, saveForm, () => {
        informasi.url = null;
        foto = null;
      });
    };

    const saveObject = async (commit: boolean) => {
      const autoGoBack = commit;
      await form.save(SaveContext.Create, autoGoBack, commit);
      if (!commit) upload();
    };

    return {
      // Data
      fotoData,
      informasi,

      // Composition
      ...composition,

      // Method
      resize,
      saveObject,
    };
  },
});
