




















































































































import { defineComponent } from "@vue/composition-api";
import router from "@/router";
import TargetKeterampilanFM from "../models/targetKeterampilanForm";
import useFormModel from "@/apps/core/modules/useFormModel";

export default defineComponent({
  name: "TargetKeterampilanForm",
  components: {
    GenericAutocomplete: () => import("@/apps/core/components/GenericAutocomplete.vue"),
    GenericSelect: () => import("@/apps/core/components/GenericSelect.vue"),
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup() {
    // BUAT CONTOH!!!! -> const isCreate = !has(router.currentRoute.params.id, "id");
    const requiredFields = [
      "angkatan",
      "keterampilan",
      "target1",
      "target2",
      "target3",
    ];
    const form = new TargetKeterampilanFM(requiredFields, []);
    const composition = useFormModel(form, router.currentRoute.params.id);
    const targetKet = composition.instance;

    return {
      // Data
      targetKet,

      // Composition
      ...composition,
    };
  },
});
