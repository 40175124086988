import FormModel from "@/apps/core/models/formModel";
import { setRefreshRequest } from "../modules/stores/suratTugas";
import { SuratTugas, SuratTugasAPI } from "./suratTugas";

export default class SuratTugasFM extends FormModel {
  constructor() {
    super(new SuratTugasAPI(), new SuratTugas(), ["url"], ["catatan"], setRefreshRequest);
  }

  /*
  Yang akan digunakan di vue single file component adalah:
  - instance
  - errorMap
  - validity.getIsValid() (computed)
  - setEdited (method)
  - validate (method)
  - save (method)
  */
}
