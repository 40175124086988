import FormModel from "@/apps/core/models/formModel";
import { setRefreshRequest } from "../modules/stores/informasi";
import { Informasi, InformasiAPI } from "./informasi";

export default class InformasiFM extends FormModel {
  constructor() {
    super(new InformasiAPI(), new Informasi(), ["url"], ["catatan"], setRefreshRequest);
  }

  /*
  Yang akan digunakan di vue single file component adalah:
  - instance
  - errorMap
  - validity.getIsValid() (computed)
  - setEdited (method)
  - validate (method)
  - save (method)
  */
}
